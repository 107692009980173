import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TwoColSingleFeatureWithStats2 from "components/features/TwoColSingleFeatureWithStats2";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import IaIcon from "images/ia.png";
import Facture from "images/deslizar.png";
import FuelControl from "images/tableta.png";
import DashboardImage from "images/dashboard.png";
import Location from "images/mapa.png";
import Resource from "images/recurso.png";
import History from "images/tiempo.png";
import Satelite from "images/satelite.png";
import Drivers from "images/conductor.png";
import Management2 from "images/management2.png";
import DashboardSvg from "images/stats-illustration.svg";
import DriverImage from "images/driverimage.jpeg";
import TruckMaintenance from "images/truck-maintenance.jpeg";
import Graphs1 from "images/dashboard1.jpeg";
import Graphs2 from "images/graps2.png";
import CmpcImage from "images/cpmc.png";
import CorfoImage from "images/corfo.png";
import SemillaCorfo from "images/semilla-corfo.png";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import Mantenimiento from "images/mantenimiento.png";

import TruckAbout from "images/about-1.jpeg";
import LogisticAbout from "images/about-2.jpeg";
import DigitalAbout from "images/about-3.jpeg";

const HighlightedText = tw.span`text-primary-500`;

export default () => {
  const messageWsp =
    "Hola, les escribo desde el sitio web de SmartTruck. ¿Están disponibles?";
  const wspNumber = "56943502792";
  const cardsFeatures = [
    {
      imageSrc: IaIcon,
      title: "Asignación inteligente",
      description:
        "Mejorar la productividad y reduce los costos asignando viajes a la flota utilizando algoritmos de inteligencia artificial",
    },
    {
      imageSrc: Facture,
      title: "Facturación",
      description:
        "Monitorea la productividad de la flota conociendo en cada momento su facturación y la meta mensual proyectada",
    },
    {
      imageSrc: FuelControl,
      title: "Control de combustible",
      description:
        "Controla el consumo y rendimiento de combustible de la flota",
    },
    {
      imageSrc: DashboardImage,
      title: "Dashboard operacional",
      description:
        "Visualiza el desempeño de la “Central de Operaciones” y de cada equipo en todo momento.",
    },
    {
      imageSrc: Location,
      title: "Ubicación de predios y destinos",
      description:
        "Visualiza en un mapa satelital la ubicación de los predios  y destinos.",
    },
    {
      imageSrc: Drivers,
      title: "Gestión de conductores",
      description:
        "Gestiona las certificaciones Corma, licencia de conducir, licencias médicas, vacaciones y permisos.",
    },
    {
      imageSrc: History,
      title: "Historial de viajes",
      description:
        "Revisa en cualquier momento el historial de viajes realizados, cancelados y adicionales.",
    },
    {
      imageSrc: Satelite,
      title: "Vista satelital",
      description:
        "Visualiza la ubicación de cada equipo en un mapa satelital.",
    },
    {
      imageSrc: Resource,
      title: "Asignación de conductores",
      description: "Asigna en cada turno los conductores de cada equipo.",
    },
    {
      imageSrc: Mantenimiento,
      title: "Mantenimiento preventivo",
      description:
        "Controla, automatiza y programa el mantenimiento de tu flota.",
    },
  ];

  const statics = [
    {
      key: "Clientes",
      value: "5+",
    },
    {
      key: "Equipos",
      value: "250+",
    },
    {
      key: "Reconocimientos",
      value: "3+",
    },
  ];
  const aboutRewards = [
    {
      postImageSrc: TruckAbout,
      title: "Expertos en logística y desarrollo de plataformas",
      description:
        "Contamos con un equipo experto, entre ellos un doctorado en logística  con estudios en USA y un equipo de desarrollo de primer nivel.",
    },
    {
      postImageSrc: LogisticAbout,
      title: "Especialistas en transporte forestal ",
      description:
        "Contamos con profesionales con basta experiencia en la gestión del transporte forestal.",
    },
    {
      postImageSrc: DigitalAbout,
      title: "Desarrollo basados en las necesidades de nuestros clientes",
      description:
        "Diseñamos y adaptamos nuestra soluciones a las necesidades del cliente.",
    },
  ];
  const rewards = [
    {
      postImageSrc: CorfoImage,
      title: "Consolida y expande Corfo",
      authorName:
        "Adjudicación del proyecto Consolida y Expande 2022,  22CYE-201725-CORFO 2022 (US $51.000), obteniendo el primer lugar a nivel nacional de un total de 50 proyectos.",
      url: "https://corfo.cl/sites/cpp/homecorfo",
    },
    {
      postImageSrc: SemillaCorfo,
      title: "Semilla Corfo",
      authorName:
        "Obtención de Semilla Inicia 2019 19INI-112939-CORFO 2019 (US $25.000).",
      url: "https://corfo.cl/sites/cpp/homecorfo",
    },
    {
      postImageSrc: CmpcImage,
      title: "Mentores CMPC",
      authorName:
        "Distinción máxima en el programa Mentores CMPC 2020. Nuestro software fue elegido como la solución tecnológica con mayor potencial en un total de 7 seleccionadas a nivel nacional.",
      url: "https://www.cmpc.com/",
    },
  ];
  return (
    <AnimationRevealPage>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href={
          new URL(
            `https://api.whatsapp.com/send?phone=${wspNumber}&text=${messageWsp}`
          )
        }
        className="float"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>

      <a
        href="mailto:contacto@smarttruck.cl"
        className="float2"
        target="_blank"
        rel="noreferrer"
      >
        <div className="container-contact">
          <i className="fa fa-envelope my-float"></i>
        </div>
      </a>
      <Hero />
      <Features
        cards={cardsFeatures}
        subheading=""
        description="SmartTruck es un software basado en conocimiento científico y tecnología, que propone asignar viajes a la flota de transporte utilizando algoritmos de optimización e inteligencia artificial, integrados en una plataforma web, con el objetivo de mejorar la eficiencia de la flota."
        heading={
          <>
            Nuestras <HighlightedText>Características</HighlightedText>
          </>
        }
      />
      <TwoColSingleFeatureWithStats2
        subheading=""
        primaryButtonText=""
        statistics={statics}
        imageSrc={Management2}
        description="Asigna viajes a la flota mediante algoritmos de optimización e inteligencia artificial, mejorando la eficiencia y productividad de tu flota."
        heading={
          <>
            Mejora la productividad de tu flota con la asignación{" "}
            <span tw="text-primary-500">inteligente</span> de viajes <wbr />
          </>
        }
      ></TwoColSingleFeatureWithStats2>
      <MainFeature
        subheading=""
        imageSrc={DashboardSvg}
        description="Controla y monitorea el consumo de combustible de tus equipos, permitiendo detectar aquellos rendimientos deficientes."
        heading={
          <>
            Conoce el consumo de <HighlightedText>Combustible</HighlightedText>
          </>
        }
      />
      <TwoColSingleFeatureWithStats2
        subheading=""
        primaryButtonText=""
        statistics={[]}
        imageSrc={Graphs1}
        description="Monitorea la productividad de la flota en todo momento, visualizando el avance de su facturación respecto a la meta proyectada"
        heading={
          <>
            Visualiza la <span tw="text-primary-500">facturación</span> de tu
            empresa en cualquier momento{" "}
          </>
        }
      ></TwoColSingleFeatureWithStats2>
      <MainFeature
        subheading=""
        imageSrc={Graphs2}
        description="Monitorea en línea el desempeño operacional de los equipos y conductores, con el objeto de mejorar la gestión, asignación y productividad"
        heading={
          <>
            Analiza el <HighlightedText>desempeño operacional</HighlightedText>{" "}
            de la flota
          </>
        }
      />
      <TwoColSingleFeatureWithStats2
        subheading=""
        primaryButtonText=""
        statistics={[]}
        imageSrc={DriverImage}
        description="Visualiza y gestiona el desempeño de todos tus conductores, estableciendo los mecanismos de alerta para el vencimiento de certificaciones Corma, licencia de conducir, registro de licencias médicas, vacaciones y permisos."
        heading={
          <>
            {" "}
            <span tw="text-primary-500">Gestiona</span> tus conductores en
            cualquier momento{" "}
          </>
        }
      ></TwoColSingleFeatureWithStats2>
      <MainFeature
        subheading=""
        imageSrc={TruckMaintenance}
        description="Controla, automatiza y programaa el mantenimiento preventivo de tu flota. Tu defines los márgenes de alertas por días, horas y kilómetros  para realizar la gestión sobre tus equipos."
        heading={
          <>
            <HighlightedText>Automatiza</HighlightedText> el mantenimiento
            preventivo de tu flota
          </>
        }
      />
      <Blog
        subheading=""
        heading={<>Premios y Reconocimientos</>}
        posts={rewards}
      />
      <Blog
        secondCard={true}
        subheading="Nosotros"
        description="Somos una startup de la región del Bio-Bio que se dedica al desarrollo de soluciones tecnológicas para resolver problemas de logística en el sector forestal utilizando conocimiento científico y tecnologías de vanguardia. Nuestro objetivo es apoyar a las empresas del transporte forestal a mejorar su productividad y con ello optimizar el uso de su flota.
        "
        heading={<>¿Por qué SmartTruck?</>}
        posts={aboutRewards}
      />
      {/* <SimpleContactUs></SimpleContactUs> */}
      <MiniCenteredFooter></MiniCenteredFooter>
    </AnimationRevealPage>
  );
};
